<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteSecretDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <i class="el-type mdi mdi-key-variant title-icon"></i> <span class="headline">{{ $t('message.secret_deletion') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="successMessage">
              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
              >
                {{ successMessage }}
              </v-alert>
            </p>

            <span v-if="secret" v-html="$t('message.confirm_delete_secret', {secretName: secret.name})"></span>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDeleteSecretDialog()"
          >
            {{ $t('message.cancel') }}
          </v-btn>

          <button type="button" :disabled="loading" v-on:click="deleteSecret(secret)" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.validate') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'DeleteSecretDialog',
  data: () => ({
    successMessage: null,
    loading: false,
    errors: []
  }),
  props: {
    secret: Object,
    deleteSecretDialogState: Boolean
  },
  computed: {
    secrets () {
      return this.$store.getters.getSecrets
    }
  },
  methods: {
    deleteSecret (secret) {
      this.loading = true

      Vue.prototype.$http
        .delete(config.apiUrl + '/secrets/' + secret.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            definitive: true
          }
        })
        .then(response => {
          if (response.status === 202) {
            this.$store.commit('deleteSecret', secret)
            this.successMessage = this.$t('message.secret_deleted')
            this.errors = []
            this.closeDeleteSecretDialog()
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.secret_deletion_error')
            })
          }
        })
    },
    closeDeleteSecretDialog () {
      this.$emit('closeDeleteSecretDialog', false)
    }
  }
}
</script>
