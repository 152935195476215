<template>
  <v-row justify="center">
    <v-dialog
      v-model="updateSecretDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <i class="el-type mdi mdi-key-variant title-icon"></i> <span class="headline">{{ $t('message.secret_update') }}</span>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <v-container>
            <v-card-text>
              <p v-if="successMessage">
                <v-alert
                  text
                  dense
                  color="teal"
                  border="left"
                  type="success"
                >
                  {{ successMessage }}
                </v-alert>
              </p>

              <p v-if="errors.length">
                <v-alert
                  text
                  dense
                  type="error"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error.title }}
                </v-alert>
              </p>

              <v-text-field
                autofocus
                v-model="secretName"
                :rules="secretNameRules"
                :label="$t('message.secret_name') + ' *'"
                required
              ></v-text-field>

              <v-text-field
                v-model="secretUrl"
                :rules="secretUrlRules"
                :label="$t('message.label_url')"
              ></v-text-field>

              <v-text-field
                v-model="secretLogin"
                :label="$t('message.label_login')"
              ></v-text-field>

              <v-text-field
                v-model="secretPassword"
                :label="$t('message.label_password')"
              ></v-text-field>

              <v-text-field
                v-model="secretComment"
                :label="$t('message.label_comment')"
              ></v-text-field>
            </v-card-text>
          </v-container>
          <div class="float-left ml-6 mt-3">
            <span class="red--text font-italic v-size--small">{{ $t('message.mandatory_field') }}</span>
          </div>
          <v-card-actions class="col-sm-12 col-md-auto">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeUpdateSecretDialog()"
            >
              {{ $t('message.cancel') }}
            </v-btn>

            <button type="submit" :disabled="!valid || loading" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
              <span class="v-btn__content">{{ $t('message.validate') }}</span>
            </button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'UpdateSecretDialog',
  data: () => ({
    valid: false,
    successMessage: null,
    loading: false,
    errors: [],
    secretName: null,
    secretLogin: null,
    secretPassword: null,
    secretUrl: null,
    secretComment: null
  }),
  props: {
    secret: Object,
    updateSecretDialogState: Boolean
  },
  created () {
    if (this.secret) {
      this.getSecret(this.secret)
    }
  },
  destroyed () {
  },
  computed: {
    secrets () {
      return this.$store.getters.getSecrets
    },
    secretNameRules () {
      return [
        v => !!v || this.$t('message.secret_name_mandatory')
      ]
    },
    secretUrlRules () {
      return [
        v => (/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(v) || !v) || this.$t('message.url_format_error'),
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.putSecret()
      }
    },
    getSecret (secret) {
      if (!secret) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/secrets/' + secret.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            log: false
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.secretName = response.data.name
            this.secretLogin = response.data.login
            this.secretPassword = response.data.password
            this.secretUrl = response.data.url
            this.secretComment = response.data.comment
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    putSecret () {
      this.successMessage = null
      this.errors = []

      const data = {
        id: this.secret.id,
        name: this.secretName,
        login: this.secretLogin,
        password: this.secretPassword,
        url: this.secretUrl,
        comment: this.secretComment,
        vault: this.$store.getters.getCurrentVault.id
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/secrets/' + this.secret.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const secretData = response.data

            if (secretData) {
              this.secret.name = secretData.name
              this.successMessage = this.$t('message.secret_has_been_updated')
              this.errors = []
              this.closeUpdateSecretDialog()
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.secret_update_error')
            })
          }
        })
    },
    closeUpdateSecretDialog () {
      this.$emit('closeUpdateSecretDialog', false)
    }
  }
}
</script>
