<template>
  <v-row justify="center">
    <v-dialog
      v-model="createFolderDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.folder_creation') }}</span>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <v-container>
            <v-card-text>
              <p v-if="successMessage">
                <v-alert
                  text
                  dense
                  color="teal"
                  border="left"
                  type="success"
                >
                  {{ successMessage }}
                </v-alert>
              </p>

              <p v-if="errors.length">
                <v-alert
                  text
                  dense
                  type="error"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error.title }}
                </v-alert>
              </p>

              <v-text-field
                autofocus
                v-model="folderName"
                :rules="folderNameRules"
                :label="$t('message.folder_name') + ' *'"
                required
              ></v-text-field>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeCreateFolderDialog()"
            >
              {{ $t('message.cancel') }}
            </v-btn>

            <button type="submit" :disabled="!valid || loading" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
              <span class="v-btn__content">{{ $t('message.validate') }}</span>
            </button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import slugify from 'slugify'
import config from '../../../../config'

export default {
  name: 'CreateFolderDialog',
  data: () => ({
    valid: false,
    successMessage: null,
    loading: false,
    errors: [],
    folderName: null
  }),
  props: {
    createFolderDialogState: Boolean
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currentRootFolder () {
      const currentRoute = this.$route
      const currentRootFolders = this.$store.getters.getCurrentRootFolders
      const routeParams = currentRoute.params

      let currentRootFolderName = null

      if (this.user.rcu) {
        currentRootFolderName = currentRoute.name === 'SecretList2' ? 'secrets' : routeParams.rootFolderName
      } else {
        currentRootFolderName = currentRoute.name === 'SecretList' ? 'mes-secrets' : routeParams.rootFolderName
      }

      if (!currentRootFolderName) {
        return null
      }

      for (let i = 0; i < currentRootFolders.length; i++) {
        const currentRootFolder = currentRootFolders[i]
        const rootFolderName = currentRootFolder.name

        if (slugify(rootFolderName, { lower: true }) === currentRootFolderName ||
          slugify(rootFolderName, { lower: true }) === 'secrets') {
          return currentRootFolder
        }
      }

      return null
    },
    folders () {
      return this.$store.getters.getFolders
    },
    folderNameRules () {
      return [
        v => !!v || this.$t('message.mandatory_folder_name')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.postFolder()
      }
    },
    postFolder () {
      const queryFolder = this.$route.query.folder
      const parentFolder = queryFolder || this.currentRootFolder.id

      const data = {
        name: this.folderName,
        vault: {
          id: this.$store.getters.getCurrentVault.id
        },
        parent: {
          id: parentFolder
        }
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/folders', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const folder = response.data

            if (folder) {
              this.$store.commit('addFolder', folder)
              this.successMessage = this.$t('message.folder_has_been_created')
              this.errors = []
              this.closeCreateFolderDialog()
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.folder_creation_error')
            })
          }
        })
    },
    closeCreateFolderDialog () {
      this.$emit('closeCreateFolderDialog', false)
    }
  }
}
</script>
