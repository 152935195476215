<template>
  <div id="content"
       @mousemove="onMouseMove($event)"
       @dragover.prevent.stop="onDragover($event)"
       @dragenter.prevent.stop="onDragenter($event)"
       @dragleave.prevent.stop="onDragleave($event)"
       @dragend.prevent.stop="onDragend($event)"
       @drop.prevent.stop="onDrop($event)">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" v-if="currentRootFolder">
            <li
              v-for="(element, index) in breadCrumb"
              :key="index"
              class="breadcrumb-item"
              :class="{
                active: index === Object.keys(breadCrumb).length - 1,
                draggable: draggable === element.id && index !== Object.keys(breadCrumb).length - 1
              }"
              @mouseover="onMouseOver($event, element)"
              @mouseup.left.stop="onMouseUpFolder($event, element)"
            >
              <span @click="openFolder(element)">
                <div v-if="index === 0">
                  {{ user.rcu ? $t('message.my_company_secrets') : $t('message.my_secrets') }}
                </div>
                <div v-else>
                  {{ element.name }}
                </div>
              </span>
            </li>
          </ol>
        </nav>
        <div class="btn-mass-actions">
          <label v-if="hasEditableContent" class="btn btn-sm btn-success" :title="$t('message.create_secret')" @click="openCreateSecretDialog()">
            <i class="fas fa-file-upload"></i><span>{{ $t('message.create_secret') }}</span>
          </label>
          <button v-if="hasEditableContent" class="btn btn-sm btn-primary" :title="$t('message.create_new_folder')" @click="openCreateFolderDialog()"><i class="fas fa-folder-plus"></i><span>{{ $t('message.create_new_folder') }}</span></button>
          <button v-if="hasDeletableContent" class="btn btn-sm btn-danger" :title="$t('message.delete_selected_secrets')" @click="openDeleteSelectedDialog()" :disabled="checkboxCount <= 0"><i class="fas fa-trash"></i><span>{{ $t('message.delete_selection') }}</span></button>
        </div>
      </header>

      <v-alert
        text
        dense
        type="error"
        v-if="mkError !== null"
      >
        {{ mkError }}
      </v-alert>

      <v-snackbar
        v-model="snackbar"
      >
        {{ successCopyMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="success"
            text
            centered
            @click="snackbar = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>

      <div id="overlay" v-if="dragged.length && hasEditableContent" @mouseup="onMouseUpOverlay()"></div>

      <button id="tail" class="btn btn-sm btn-primary" v-if="dragged.length && hasEditableContent">
        <i class="el-type fas fa-arrow-alt-circle-right"></i> <span>{{ $t('message.move_selection') }}</span>
      </button>

      <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2" >
        <thead>
          <tr>
            <th><v-checkbox dense v-model="checkbox" @change="updateCheckboxes()" class="document-checkbox"></v-checkbox></th>
            <th>{{ $t('message.name') }}</th>
            <th class="sm-hidden">{{ $t('message.creation_date') }}</th>
            <th>{{ $t('message.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(folder, folderIndex) in folders"
            :key="'f' + folderIndex"
            :class="{ dragged: dragged.includes('f' + folder.id), draggable: draggable === folder.id, folder: true }"
          >
            <td><v-checkbox dense :value="folder.selected" v-model=folder.selected class="document-checkbox" @change="updateCheckboxCount(folder.selected)"></v-checkbox></td>
            <td
              data-label="$t('message.name')"
            >
              <div class="folder-container"
                   @click="onMouseClickFolder($event, folder)"
                   @mouseover="onMouseOver($event, folder)"
                   @mousedown.left.prevent="onMouseDown($event, folder)"
                   @mouseup.left.stop="onMouseUpFolder($event, folder)"
              >
                <i class="el-type fas fa-folder"></i><span class="ml-i2 font-weight-bold">{{ folder.name }}</span>
                <span v-if="folder.read === false && hasEditableContent === false" class="unread badge bg-danger">{{ $t('message.new') }}</span>
              </div>
            </td>
            <td data-label="$t('message.drop_date')" class="sm-hidden"></td>
            <td
              class="actions"
              data-label="$t('message.actions')"
            >
              <button v-if="hasEditableContent" :title="$t('message.rename')" @click="openUpdateFolderDialog(folder)"><i class="fas fa-pen"></i></button>
              <button v-if="hasDeletableContent" :title="$t('message.delete')" @click="openDeleteFolderDialog(folder)"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
          <tr
            v-for="(secret, secretIndex) in secrets"
            :key="'d' + secretIndex"
            :class="{ dragged: dragged.includes('d' + secret.id) }"
            @mousedown.left.prevent="onMouseDown($event, secret)"
            @mouseup.left.stop="onMouseUpSecret($event, secret)"
          >
            <td><v-checkbox dense :value="secret.selected" v-model=secret.selected class="document-checkbox" @change="updateCheckboxCount(secret.selected)"></v-checkbox></td>
            <td class="secret-name" @click="openViewSecretDialog(secret)" data-label="Nom :">
              <i class="el-type mdi mdi-key-variant"></i><span class="ml-i1 font-weight-bold">{{ secret.name }}</span>
            </td>
            <td data-label="Date de dépôt :" class="sm-hidden">
              {{ secret.createdAt ? formatDate(secret.createdAt) : '-' }}
            </td>
            <td class="actions" data-label="$t('message.actions')">
              <button :title="$t('message.view')" @click="openViewSecretDialog(secret)"><i class="fas fa-eye"></i></button>
              <button v-if="hasEditableContent" :title="$t('message.copy_password')" @click="copySecretPassword(secret)"><i class="fas fa-key"></i></button>
              <button v-if="hasEditableContent" :title="$t('message.copy_username')" @click="copySecretLogin(secret)"><i class="fas fa-user"></i></button>
              <button v-if="hasEditableContent" :title="$t('message.copy_url')" @click="copySecretUrl(secret)"><i class="fas fa-link"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <CreateFolderDialog
      :key="Date.now()"
      :create-folder-dialog-state="createFolderDialogState"
      @closeCreateFolderDialog="closeCreateFolderDialog($event)"
    ></CreateFolderDialog>

    <UpdateFolderDialog
      :key="Date.now() + 1"
      :update-folder-dialog-state="updateFolderDialogState"
      :folder="folderToUpdate"
      @closeUpdateFolderDialog="closeUpdateFolderDialog($event)"
    ></UpdateFolderDialog>

    <DeleteFolderDialog
      :key="Date.now() + 2"
      :delete-folder-dialog-state="deleteFolderDialogState"
      :folder="folderToDelete"
      :source="'secret'"
      @closeDeleteFolderDialog="closeDeleteFolderDialog($event)"
    ></DeleteFolderDialog>

    <UpdateSecretDialog
      v-if="secretToUpdate && destroyUpdateSecretComponent"
      :key="1323387998"
      :update-secret-dialog-state="updateSecretDialogState"
      :secret="secretToUpdate"
      @closeUpdateSecretDialog="closeUpdateSecretDialog($event)"
    ></UpdateSecretDialog>

    <DeleteSecretDialog
      :key="Date.now() + 3"
      :delete-secret-dialog-state="deleteSecretDialogState"
      :secret="secretToDelete"
      @closeDeleteSecretDialog="closeDeleteSecretDialog($event)"
    ></DeleteSecretDialog>

    <ViewSecretDialog
      v-if="secretToView && destroyViewSecretComponent"
      :key="31165648"
      :view-secret-dialog-state="viewSecretDialogState"
      :secret="secretToView"
      @closeViewSecretDialog="closeViewSecretDialog($event)"
      @openUpdateSecretDialog="openUpdateSecretDialog($event)"
      @openDeleteSecretDialog="openDeleteSecretDialog($event)"
    ></ViewSecretDialog>

    <DeleteSelectedDialog
      :key="Date.now() + 4"
      :delete-selected-dialog-state="deleteSelectedDialogState"
      :secrets-to-delete="secretsToDelete"
      :secret-ids="secretIds"
      :folders-to-delete="foldersToDelete"
      :folder-ids="folderIds"
      @closeDeleteSelectedDialog="closeDeleteSelectedDialog($event)"
    ></DeleteSelectedDialog>

    <EmptySelectionDialog
      :key="Date.now() + 5"
      :empty-selection-dialog-state="emptySelectionDialogState"
      @closeEmptySelectionDialog="closeEmptySelectionDialog($event)"
    ></EmptySelectionDialog>

    <CreateSecretDialog
      :key="Date.now() + 6"
      :create-secret-dialog-state="createSecretDialogState"
      @closeCreateSecretDialog="closeCreateSecretDialog($event)"
    ></CreateSecretDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import CreateFolderDialog from './Dialog/Documents/FolderDialog/CreateFolderDialog'
import UpdateFolderDialog from './Dialog/Documents/FolderDialog/UpdateFolderDialog'
import DeleteFolderDialog from './Dialog/Documents/FolderDialog/DeleteFolderDialog'
import CreateSecretDialog from './Dialog/Secrets/CreateSecretDialog'
import UpdateSecretDialog from './Dialog/Secrets/UpdateSecretDialog'
import DeleteSecretDialog from './Dialog/Secrets/DeleteSecretDialog'
import ViewSecretDialog from './Dialog/Secrets/ViewSecretDialog'
import DeleteSelectedDialog from './Dialog/Secrets/DeleteSelectedDialog'
import EmptySelectionDialog from './Dialog/Secrets/EmptySelectionDialog'
import slugify from 'slugify'
import config from '../config'

export default {
  name: 'Secrets',
  data: () => ({
    valid: false,
    checkbox: false,
    checkboxCount: 0,
    successMessage: null,
    successCopyMessage: null,
    snackbar: false,
    errors: [],
    loading: false,
    folderName: null,
    dragged: [],
    draggable: null,
    draggedFolders: [],
    draggedSecrets: [],
    timer: null,
    clickCount: 0,
    breadCrumb: [],
    folderToUpdate: null,
    folderToDelete: null,
    createFolderDialogState: false,
    updateFolderDialogState: false,
    deleteFolderDialogState: false,
    secretToUpdate: null,
    secretToDelete: null,
    secretToView: null,
    createSecretDialogState: false,
    updateSecretDialogState: false,
    deleteSecretDialogState: false,
    viewSecretDialogState: false,
    secretsToDelete: [],
    secretIds: [],
    foldersToDelete: [],
    folderIds: [],
    deleteSelectedDialogState: false,
    emptySelectionDialogState: false,
    destroyViewSecretComponent: true,
    destroyUpdateSecretComponent: true,
    xTarget: null,
    hasEditableContent: false,
    hasDeletableContent: false,
    files: [],
    mkError: null,
    showNewAccountMessage: false,
    nextPage: 1,
    mainFolder: null
  }),
  created () {
    this.$store.commit('setFolders', [])
    this.$store.commit('setSecrets', [])

    this.getFolder()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 3000)

    const currentRootFolder = this.currentRootFolder

    if (!currentRootFolder) {
      return null
    }

    const section = currentRootFolder.section

    if (!section) {
      return null
    }

    this.hasEditableContent = section.editableContent
    this.hasDeletableContent = section.deletableContent

    if (this.user.rcu) {
      document.title = config.title + ' - ' + this.$t('message.my_company_secrets')
    } else {
      document.title = config.title + ' - ' + this.$t('message.my_secrets')
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)

    this.$store.commit('setSecrets', [])
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    folderNameRules () {
      return [
        v => !!v || this.$t('message.mandatory_folder_name')
      ]
    },
    currentRootFolder () {
      const currentRoute = this.$route
      const currentRootFolders = this.$store.getters.getCurrentRootFolders
      const routeParams = currentRoute.params

      let currentRootFolderName = null

      if (this.user.rcu) {
        currentRootFolderName = currentRoute.name === 'SecretList2' ? 'secrets' : routeParams.rootFolderName
      } else {
        currentRootFolderName = currentRoute.name === 'SecretList' ? 'mes-secrets' : routeParams.rootFolderName
      }

      if (!currentRootFolderName) {
        return null
      }

      for (let i = 0; i < currentRootFolders.length; i++) {
        const currentRootFolder = currentRootFolders[i]
        const rootFolderName = currentRootFolder.name

        if (slugify(rootFolderName, { lower: true }) === currentRootFolderName ||
            slugify(rootFolderName, { lower: true }) === 'secrets') {
          return currentRootFolder
        }
      }

      return null
    },
    currentFolder () {
      const routeParams = this.$route.params
      const currentRootFolderName = routeParams.rootFolderName

      if (!currentRootFolderName) {
        return null
      }

      let key = currentRootFolderName
      const folder = this.$route.query.folder

      if (folder) {
        key += folder
      }

      return key
    },
    folders () {
      return this.$store.getters.getFolders
    },
    secrets () {
      return this.$store.getters.getSecrets
    },
    hasIgnoredUsageMsg () {
      return this.$store.getters.hasIgnoredUsageMsg
    },
    isHandlingSecretScroll () {
      return this.$store.getters.isHandlingSecretScroll
    }
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = wrapperOffsetHeight - (document.documentElement.scrollTop + window.innerHeight) <= 200

      if (bottomOfWindow && !this.isHandlingSecretScroll) {
        this.getSecrets(this.mainFolder)
      }
    },
    onDragover () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.add('is-dragover')
    },
    onDragenter () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.add('is-dragover')
    },
    onDragleave () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.remove('is-dragover')
    },
    onDragend () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.remove('is-dragover')
    },
    onMouseMove (event) {
      if (!this.hasEditableContent) {
        return
      }

      if (this.dragged.length) {
        const tail = document.getElementById('tail')

        tail.style.top = event.pageY + 'px'
        tail.style.left = event.pageX + 'px'
      }
    },
    onMouseClickFolder (event, folder) {
      if (!folder) {
        return
      }

      for (let i = 0; i < this.draggedFolders.length; i++) {
        const currentFolder = this.draggedFolders[i]

        if (currentFolder.id === folder.id) {
          return
        }
      }

      this.openFolder(folder)
    },
    onMouseDown (event, element) {
      if (!this.hasEditableContent) {
        return
      }

      this.timer = setTimeout(() => {
        element.selected = true
        this.draggedFolders = this.folders.filter(element => element.selected === true)
        this.draggedSecrets = this.secrets.filter(element => element.selected === true)
        this.dragged = this.draggedFolders.map(element => 'f' + element.id)
        this.dragged = this.dragged.concat(this.draggedSecrets.map(element => 'd' + element.id))
      }, 150)
    },
    onMouseUpOverlay () {
      if (!this.hasEditableContent) {
        return
      }

      this.dragged = []
      this.draggable = null

      for (let i = 0; i < this.draggedFolders.length; i++) {
        const currentFolder = this.draggedFolders[i]

        currentFolder.selected = false
      }

      this.draggedFolders = []

      for (let i = 0; i < this.draggedSecrets.length; i++) {
        const currentSecret = this.draggedSecrets[i]

        currentSecret.selected = false
      }

      this.draggedSecrets = []
    },
    onMouseUpFolder (event, folder) {
      if (!this.hasEditableContent) {
        return
      }

      if ((this.draggedFolders.length === 0 && this.draggedSecrets.length === 0) || this.dragged.includes('f' + folder.id)) {
        this.dragged = []
        this.draggable = null
        // this.draggedFolders = []
        this.draggedSecrets = []
        this.updateCheckboxes()

        return
      }

      const foldersData = []

      for (let i = 0; i < this.draggedFolders.length; i++) {
        const currentFolder = this.draggedFolders[i]

        if (currentFolder.id !== folder.id) {
          foldersData.push({
            id: currentFolder.id,
            parent: {
              id: folder.id
            }
          })
        }
      }

      if (foldersData.length) {
        const foldersFormData = new FormData()
        foldersFormData.append('data', JSON.stringify(foldersData))

        Vue.prototype.$http
          .post(config.apiUrl + '/folders', foldersFormData, {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.getToken
            },
            params: {
              _method: 'PUT'
            }
          })
          .then(response => {
            if (response.status === 200) {
              const folders = response.data

              if (folders) {
                this.$store.commit('deleteFolders', folders)
                this.successMessage = this.$t('message.selection_moved')
                this.errors = []
              }
            }
          })
          .catch(e => {
            const response = e.response

            if (!response) {
              return
            }

            if (response.status === 400) {
              const data = response.data
              this.errors = data.violations
              this.successMessage = null
            }

            if (response.status === 500) {
              this.errors.push({
                title: this.$t('message.error_moving_selection')
              })
            }
          })
      }

      const secretsData = []

      for (let i = 0; i < this.draggedSecrets.length; i++) {
        const currentSecret = this.draggedSecrets[i]

        secretsData.push({
          id: currentSecret.id,
          folder: folder.id,
          vault: this.$store.getters.getCurrentVault.id
        })
      }

      if (secretsData.length) {
        const secretsFormData = new FormData()
        secretsFormData.append('data', JSON.stringify(secretsData))

        Vue.prototype.$http
          .post(config.apiUrl + '/secrets', secretsFormData, {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.getToken
            },
            params: {
              _method: 'PUT'
            }
          })
          .then(response => {
            if (response.status === 200) {
              const secrets = response.data

              if (secrets) {
                this.$store.commit('deleteSecrets', secrets)
                this.successMessage = this.$t('message.selection_moved')
                this.errors = []

                if (this.secrets.length === 0) {
                  this.nextPage = this.nextPage - 1

                  this.getSecrets(this.mainFolder)
                }
              }
            }
          })
          .catch(e => {
            const response = e.response

            if (!response) {
              return
            }

            if (response.status === 400) {
              const data = response.data
              this.errors = data.violations
              this.successMessage = null
            }

            if (response.status === 500) {
              this.errors.push({
                title: this.$t('message.error_moving_selection')
              })
            }
          })
      }

      this.dragged = []
      this.draggable = null
      this.draggedFolders = []
      this.draggedSecrets = []
      this.checkbox = false
      this.updateCheckboxes()
    },
    onMouseUpSecret (event, secret) {
      if (!this.hasEditableContent) {
        return
      }

      this.clickCount += 1
      clearTimeout(this.timer)
      setTimeout(() => { this.clickCount = 0 }, 500)

      this.onMouseUpOverlay()
    },
    onMouseOver (event, folder) {
      if (!this.hasEditableContent) {
        return
      }

      if (this.dragged.length && !this.dragged.includes('f' + folder.id)) {
        this.draggable = folder.id
      }
    },
    getFolder () {
      if (!this.currentRootFolder) {
        return
      }

      const queryFolder = this.$route.query.folder
      const folder = queryFolder || this.currentRootFolder.id

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/folders/' + folder, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            page: this.nextPage
          }
        })
        .then(response => {
          if (response.status === 200) {
            const folder = response.data

            this.mainFolder = folder

            if (folder) {
              this.breadCrumb = folder.breadCrumb

              const children = folder.children.filter(element => element.deleted === false)

              if (children && children.length) {
                this.$store.commit('setFolders', children)
              }

              this.getSecrets(folder)
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.mkError = this.$t('message.error_retrieving_items')
          }

          this.loading = false
        })
    },
    getSecrets (folder) {
      if (!folder) {
        return
      }

      this.$store.commit('setHandlingSecretScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/secrets', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            folder: folder.id,
            page: this.nextPage
          }
        })
        .then(response => {
          if (response.status === 200) {
            const secrets = response.data

            if (secrets && secrets.length) {
              this.$store.commit('setSecrets', secrets)

              this.nextPage = this.nextPage + 1
            }

            this.xTarget = response.headers['x-target']
          }

          this.loading = false
          this.$store.commit('setHandlingSecretScroll', false)
        })
        .catch(e => {
          this.$store.commit('setSecrets', [])
          this.$store.commit('setHandlingSecretScroll', false)

          const response = e.response

          if (response.status === 404) {
            this.mkError = this.$t('message.maintenance_secrets_unavailable')
          }

          if (response.status === 500) {
            this.mkError = this.$t('message.error_retrieving_items')
          }

          this.loading = false
        })
    },
    openFolder (folder) {
      if (folder) {
        const rootFolderSlug = slugify(this.currentRootFolder.name, { lower: true })

        this.$router.push({ name: 'SecretList', params: { rootFolderName: rootFolderSlug }, query: { folder: folder.id } }).catch(err => err)
      }
    },
    updateCheckboxes () {
      this.checkboxCount = 0

      for (let i = 0; i < this.folders.length; i++) {
        const currentFolder = this.folders[i]
        currentFolder.selected = this.checkbox
        this.updateCheckboxCount(currentFolder.selected)
      }

      for (let i = 0; i < this.secrets.length; i++) {
        const currentSecret = this.secrets[i]
        currentSecret.selected = this.checkbox
        this.updateCheckboxCount(currentSecret.selected)
      }
    },
    updateCheckboxCount (value) {
      value === true ? this.checkboxCount++ : (this.checkboxCount === 0 ? this.checkboxCount = 0 : this.checkboxCount--)
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    },
    openCreateFolderDialog () {
      if (!this.hasEditableContent) {
        return
      }

      this.createFolderDialogState = true
    },
    closeCreateFolderDialog () {
      this.createFolderDialogState = false
    },
    openUpdateFolderDialog (folder) {
      if (!this.hasEditableContent) {
        return
      }

      this.updateFolderDialogState = true
      this.folderToUpdate = folder
    },
    closeUpdateFolderDialog () {
      this.updateFolderDialogState = false
    },
    openDeleteFolderDialog (folder) {
      if (!this.hasDeletableContent) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/folders/' + folder.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: 'secret'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.deleteFolderDialogState = true
            this.folderToDelete = response.data
          }

          this.loading = false
        })
        .catch(e => {
        })
    },
    closeDeleteFolderDialog () {
      this.deleteFolderDialogState = false
    },
    openUpdateSecretDialog (secret) {
      this.viewSecretDialogState = false
      this.destroyViewSecretComponent = false
      this.destroyUpdateSecretComponent = true
      this.updateSecretDialogState = true
      this.secretToUpdate = secret
    },
    closeUpdateSecretDialog () {
      this.updateSecretDialogState = false
      this.destroyUpdateSecretComponent = false
    },
    openDeleteSecretDialog (secret) {
      this.deleteSecretDialogState = true
      this.viewSecretDialogState = false
      this.secretToDelete = secret
    },
    closeDeleteSecretDialog () {
      this.deleteSecretDialogState = false

      if (this.secrets.length === 0) {
        this.nextPage = this.nextPage - 1

        this.getSecrets(this.mainFolder)
      }
    },
    openViewSecretDialog (secret) {
      this.viewSecretDialogState = true
      this.secretToView = secret
      this.destroyViewSecretComponent = true
    },
    closeViewSecretDialog () {
      this.viewSecretDialogState = false
      this.destroyViewSecretComponent = false
    },
    openDeleteSelectedDialog () {
      if (!this.hasDeletableContent) {
        return
      }

      this.loading = true

      const secretIds = this.secrets
        .filter(element => element.selected === true)
        .map(element => element.id)

      const folderIds = this.folders
        .filter(element => element.selected === true)
        .map(element => element.id)

      this.secretIds = secretIds
      this.secretsToDelete = this.secrets.filter(element => element.selected === true)
      this.folderIds = folderIds

      if (this.folderIds.length === 0) {
        this.foldersToDelete = []

        this.loading = false

        if (secretIds.length > 0 || folderIds.length > 0) {
          this.deleteSelectedDialogState = true
        } else {
          this.checkboxCount = 0
          // this.emptySelectionDialogState = true
        }

        return
      }

      const data = {
        ids: folderIds.join(',')
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/folders-infos', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: 'secret'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.foldersToDelete = response.data
          }

          if (secretIds.length > 0 || folderIds.length > 0) {
            this.deleteSelectedDialogState = true
          } else {
            this.checkboxCount = 0
            // this.emptySelectionDialogState = true
          }

          this.loading = false
        })
        .catch(e => {
        })
    },
    closeDeleteSelectedDialog () {
      this.deleteSelectedDialogState = false
      this.checkbox = false
      this.updateCheckboxes()

      if (this.secrets.length === 0) {
        this.nextPage = this.nextPage - 1

        this.getSecrets(this.mainFolder)
      }
    },
    closeEmptySelectionDialog () {
      this.emptySelectionDialogState = false
      this.checkbox = false
      this.updateCheckboxes()
    },
    openCreateSecretDialog () {
      if (!this.hasEditableContent) {
        return
      }

      this.createSecretDialogState = true
    },
    closeCreateSecretDialog () {
      this.createSecretDialogState = false
    },
    async getSecret (secret) {
      if (!secret) {
        return
      }

      this.loading = true

      return await Vue.prototype.$http
        .get(config.apiUrl + '/secrets/' + secret.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          }
        })
        .then(response => {
          this.loading = false

          return response
        })
        .catch(e => {
          this.loading = false
        })
    },
    async copySecret (secret, attribut) {
      if (!secret) {
        return
      }

      this.loading = true

      return await Vue.prototype.$http
        .get(config.apiUrl + '/copy-secrets/' + secret.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            attribut: attribut
          }
        })
        .then(response => {
          this.loading = false

          return response
        })
        .catch(e => {
          this.loading = false
        })
    },
    copySecretUrl (secret) {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(secret, 'url').then(response => {
        navigator.clipboard.writeText(response.data.url)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.url_copied_successfully')
      })
    },
    copySecretLogin (secret) {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(secret, 'login').then(response => {
        navigator.clipboard.writeText(response.data.login)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.username_copied_successfully')
      })
    },
    copySecretPassword (secret) {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(secret, 'password').then(response => {
        navigator.clipboard.writeText(response.data.password)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.password_copied_successfully')
      })
    }
  },
  watch: {
    clickCount () {
      if (this.clickCount === 2) {
        this.clickCount = 0
      }
    }
  },
  components: {
    CreateFolderDialog,
    UpdateFolderDialog,
    DeleteFolderDialog,
    CreateSecretDialog,
    UpdateSecretDialog,
    DeleteSecretDialog,
    ViewSecretDialog,
    DeleteSelectedDialog,
    EmptySelectionDialog
  }
}
</script>
