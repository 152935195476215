<template>
  <v-row justify="center">
    <v-dialog
      v-model="emptySelectionDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-container>
          <v-card-text>
            <span><strong>{{ $t('message.no_selection') }}</strong></span>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEmptySelectionDialog()"
          >
            {{ $t('message.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'EmptySelectionDialog',
  props: {
    emptySelectionDialogState: Boolean
  },
  methods: {
    closeEmptySelectionDialog () {
      this.$emit('closeEmptySelectionDialog', false)
    }
  }
}
</script>
