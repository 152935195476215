<template>
  <v-row justify="center">
    <v-dialog
      v-model="updateFolderDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.folder_update') }}</span>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <v-container>
            <v-card-text>
              <p v-if="successMessage">
                <v-alert
                  text
                  dense
                  color="teal"
                  border="left"
                  type="success"
                >
                  {{ successMessage }}
                </v-alert>
              </p>

              <p v-if="errors.length">
                <v-alert
                  text
                  dense
                  type="error"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error.title }}
                </v-alert>
              </p>

              <v-text-field
                autofocus
                v-model="folderName"
                :rules="folderNameRules"
                :label="$t('message.folder_name') + ' *'"
                required
              ></v-text-field>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeUpdateFolderDialog()"
            >
              {{ $t('message.cancel') }}
            </v-btn>

            <button type="submit" :disabled="!valid || loading" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
              <span class="v-btn__content">{{ $t('message.validate') }}</span>
            </button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../../config'

export default {
  name: 'UpdateFolderDialog',
  data: () => ({
    valid: false,
    successMessage: null,
    loading: false,
    errors: [],
    folderName: null
  }),
  props: {
    folder: Object,
    updateFolderDialogState: Boolean
  },
  created () {
    if (this.folder) {
      this.folderName = this.folder.name
    }
  },
  computed: {
    folders () {
      return this.$store.getters.getFolders
    },
    folderNameRules () {
      return [
        v => !!v || this.$t('message.mandatory_folder_name')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.putFolder()
      }
    },
    putFolder () {
      this.successMessage = null
      this.errors = []

      const data = {
        name: this.folderName
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/folders/' + this.folder.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const folderData = response.data

            if (folderData) {
              this.folder.name = folderData.name
              this.successMessage = this.$t('message.folder_has_been_updated')
              this.errors = []
              this.closeUpdateFolderDialog()
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.folder_update_error')
            })
          }
        })
    },
    closeUpdateFolderDialog () {
      this.$emit('closeUpdateFolderDialog', false)
    }
  }
}
</script>
