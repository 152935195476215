<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteFolderDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.folder_deletion') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="successMessage">
              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
              >
                {{ successMessage }}
              </v-alert>
            </p>
            <span v-if="folder" v-html="$t('message.confirm_folder_deletion', { folderName: folder.name, nbChildren: folder.nbChildren })"></span>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDeleteFolderDialog()"
          >
            {{ $t('message.cancel') }}
          </v-btn>

          <button type="button" :disabled="loading" v-on:click="deleteFolder(folder)" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.validate') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../../config'

export default {
  name: 'DeleteFolderDialog',
  data: () => ({
    successMessage: null,
    loading: false,
    nbChildren: 0,
    errors: []
  }),
  props: {
    folder: Object,
    source: String,
    deleteFolderDialogState: Boolean
  },
  created () {
    // this.getFolder(this.folder)
  },
  methods: {
    getFolder (folder) {
      if (folder === null) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/folders/' + folder.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: this.source
          }
        })
        .then(response => {
          if (response.status === 200) {
            const folder = response.data

            if (folder) {
              this.nbChildren = folder.nbChildren
            }
          }

          this.loading = false
        })
        .catch(e => {
        })
    },
    deleteFolder (folder) {
      this.loading = true

      Vue.prototype.$http
        .delete(config.apiUrl + '/folders/' + folder.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: this.source
          }
        })
        .then(response => {
          if (response.status === 202) {
            this.$store.commit('deleteFolder', folder)
            this.successMessage = this.$t('message.folder_deleted')
            this.errors = []
            this.closeDeleteFolderDialog()
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.folder_deletion_error')
            })
          }
        })
    },
    closeDeleteFolderDialog () {
      this.$emit('closeDeleteFolderDialog', false)
    }
  }
}
</script>
