<template>
  <v-row justify="center">
    <v-dialog
      v-model="createSecretDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <i class="el-type mdi mdi-key-variant title-icon"></i> <span class="headline">{{ $t('message.secret_creation') }}</span>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <v-container>
            <v-card-text>
              <p v-if="successMessage">
                <v-alert
                  text
                  dense
                  color="teal"
                  border="left"
                  type="success"
                >
                  {{ successMessage }}
                </v-alert>
              </p>

              <p v-if="errors.length">
                <v-alert
                  text
                  dense
                  type="error"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error.title }}
                </v-alert>
              </p>

              <v-text-field
                autofocus
                v-model="secretName"
                :rules="secretNameRules"
                :label="$t('message.secret_name') + ' *'"
                required
              ></v-text-field>

              <v-text-field
                v-model="secretUrl"
                :rules="secretUrlRules"
                :label="$t('message.secret_url')"
              ></v-text-field>

              <v-text-field
                v-model="secretLogin"
                :label="$t('message.secret_identifier')"
              ></v-text-field>

              <v-text-field
                v-model="secretPassword"
                :label="$t('message.secret_password')"
              ></v-text-field>

              <v-text-field
                v-model="secretComment"
                :label="$t('message.secret_comment')"
              ></v-text-field>
            </v-card-text>
          </v-container>
          <div class="float-left ml-6 mt-3">
            <span class="red--text font-italic v-size--small">{{ $t('message.mandatory_field') }}</span>
          </div>
          <v-card-actions class="col-sm-12 col-md-auto">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeCreateSecretDialog()"
            >
              {{ $t('message.cancel') }}
            </v-btn>

            <button type="submit" :disabled="!valid || loading" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
              <span class="v-btn__content">{{ $t('message.validate') }}</span>
            </button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import slugify from 'slugify'
import config from '../../../config'

export default {
  name: 'CreateSecretDialog',
  data: () => ({
    valid: false,
    successMessage: null,
    loading: false,
    errors: [],
    secretName: null,
    secretLogin: null,
    secretPassword: null,
    secretUrl: null,
    secretComment: null
  }),
  props: {
    createSecretDialogState: Boolean
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currentRootFolder () {
      const currentRootFolders = this.$store.getters.getCurrentRootFolders
      const currentRootFolderName = this.user.rcu === true ? 'secrets' : 'mes-secrets'

      for (let i = 0; i < currentRootFolders.length; i++) {
        const currentRootFolder = currentRootFolders[i]
        const rootFolderName = currentRootFolder.name

        if (slugify(rootFolderName, { lower: true }) === currentRootFolderName ||
          slugify(rootFolderName, { lower: true }) === 'secrets') {
          return currentRootFolder
        }
      }

      return null
    },
    folders () {
      return this.$store.getters.getFolders
    },
    secretNameRules () {
      return [
        v => !!v || this.$t('message.secret_name_mandatory')
      ]
    },
    secretUrlRules () {
      return [
        v => (/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(v) || !v) || this.$t('message.url_format_error'),
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.postSecret()
      }
    },
    postSecret () {
      const queryFolder = this.$route.query.folder
      const parentFolder = queryFolder || this.currentRootFolder.id

      const data = {
        name: this.secretName,
        login: this.secretLogin,
        password: this.secretPassword,
        url: this.secretUrl,
        comment: this.secretComment,
        vault: this.$store.getters.getCurrentVault.id,
        folder: parentFolder
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/secrets', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const secret = response.data

            if (secret) {
              this.$store.commit('addSecret', secret)
              this.successMessage = this.$t('message.secret_created')
              this.errors = []
              this.closeCreateSecretDialog()
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.secret_create_error')
            })
          }
        })
    },
    closeCreateSecretDialog () {
      this.$emit('closeCreateSecretDialog', false)
    }
  }
}
</script>
