<template>
  <v-row justify="center">
    <v-dialog
      v-model="viewSecretDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <i class="el-type mdi mdi-key-variant title-icon"></i> <span class="headline float-left">{{ $t('message.secret_view') }}</span>

          <div class="actions">
            <button data-toggle="tooltip" data-placement="top" :title="$t('message.close')" @click="closeViewSecretDialog()"><i class="fas fa-times"></i></button>
          </div>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="errors.length">
              <v-alert
                text
                dense
                type="error"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ error.title }}
              </v-alert>
            </p>

            <v-snackbar
              v-model="snackbar"
            >
              {{ successCopyMessage }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="success"
                  text
                  centered
                  @click="snackbar = false"
                >
                  {{ $t('message.ok') }}
                </v-btn>
              </template>
            </v-snackbar>

            <div id="loading-modal" v-if="loading">
              <div class="dot-flashing"></div>
            </div>

            <div class="pdf-preview" v-if="loading === false && data != null">
              <v-row>
                <v-col cols="12" lg="8">
                  <div class="secret-detail">
                    <div class="float-left">
                      <span class="font-weight-bold">{{ $t('message.secret_name') }}</span><br>
                      {{ name }}
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" lg="7">
                  <div class="secret-detail">
                    <div class="float-left">
                      <span class="font-weight-bold">{{ $t('message.secret_url') }}</span><br>
                      <a v-if="url" :href="resolveUrl(url)" target="_blank">{{ url }}</a>
                      <span v-if="!url">{{ $t('message.none_f') }}</span>
                    </div>
                    <div class="float-right" v-if="url">
                      <button :title="$t('message.copy_url')" @click="copySecretUrl()" class="mt-3"><i class="far fa-copy"></i></button>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" lg="7">
                  <div class="secret-detail">
                    <div class="float-left">
                      <span class="font-weight-bold">{{ $t('message.secret_identifier') }}</span><br>
                      <span v-if="login">{{ login }}</span>
                      <span v-if="!login">{{ $t('message.none_m') }}</span>
                    </div>
                    <div class="float-right" v-if="login">
                      <button :title="$t('message.copy_username')" @click="copySecretLogin()" class="mt-3"><i class="far fa-copy"></i></button>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" lg="7">
                  <div class="secret-detail">
                    <div class="float-left">
                      <span class="font-weight-bold">{{ $t('message.secret_password') }}</span><br>
                      <span v-if="password">{{ currentPassword }}</span>
                      <span v-if="!password">{{ $t('message.none_m') }}</span>
                    </div>
                    <div class="float-right" v-if="password">
                      <button :title="$t('message.view')" @click="showSecretPassword()" class="mr-3"><i :class='"fas fa-eye" + (showPassword ? "-slash" : "")'></i></button>
                      <button :title="$t('message.copy_password')" @click="copySecretPassword()" class="mt-3"><i class="far fa-copy"></i></button>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" lg="7">
                  <div class="secret-detail">
                    <div class="float-left">
                      <span class="font-weight-bold">{{ $t('message.secret_comment') }}</span><br>
                      {{ comment ? comment : $t('message.none_m') }}
                    </div>
                    <div class="float-right" v-if="comment">
                      <button :title="$t('message.copy_comment')" @click="copyComment()" class="mt-3"><i class="far fa-copy"></i></button>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button class="btn btn-sm btn-success mb-5 white--text font-weight-bold" :title="$t('message.edit_secret')" @click="openUpdateSecretDialog()"><i class="fas fa-pen"></i><span>{{ $t('message.edit') }}</span></button>
          <button class="btn btn-sm btn-danger  mb-5 ml-3 mr-5 white--text font-weight-bold" :title="$t('message.delete_secret')" @click="openDeleteSecretDialog()"><i class="fas fa-trash"></i><span>{{ $t('message.delete') }}</span></button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'ViewSecretDialog',
  data: () => ({
    secretToPreview: null,
    errors: [],
    page: 1,
    currentPage: 0,
    pageCount: 0,
    loading: false,
    data: null,
    name: null,
    url: null,
    login: null,
    password: null,
    comment: null,
    snackbar: false,
    successCopyMessage: null,
    showPassword: false,
    currentPassword: '********'
  }),
  props: {
    secret: Object,
    viewSecretDialogState: Boolean
  },
  created () {
    if (this.secret) {
      this.viewSecret(this.secret)
    }
  },
  methods: {
    viewSecret (secret) {
      if (!secret) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/secrets/' + secret.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            log: true
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.data = response.data
            this.name = response.data.name
            this.url = response.data.url
            this.login = response.data.login
            this.password = response.data.password
            this.comment = response.data.comment
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    async copySecret (secret, attribut) {
      if (!secret) {
        return
      }

      return await Vue.prototype.$http
        .get(config.apiUrl + '/copy-secrets/' + secret.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            attribut: attribut
          }
        })
        .then(response => {
          return response
        })
        .catch(e => {
        })
    },
    copySecretUrl () {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(this.secret, 'url').then(response => {
        navigator.clipboard.writeText(response.data.url)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.url_copy_success')
      })
    },
    copySecretLogin () {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(this.secret, 'login').then(response => {
        navigator.clipboard.writeText(response.data.login)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.username_copy_success')
      })
    },
    copySecretPassword () {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(this.secret, 'password').then(response => {
        navigator.clipboard.writeText(response.data.password)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.password_copy_success')
      })
    },
    copyComment () {
      this.successCopyMessage = null
      this.snackbar = false
      this.copySecret(this.secret, 'comment').then(response => {
        navigator.clipboard.writeText(response.data.comment)
        this.snackbar = true
        this.successCopyMessage = this.$t('message.comment_copy_success')
      })
    },
    showSecretPassword () {
      this.showPassword = !this.showPassword
      this.currentPassword = this.showPassword ? this.password : '********'
    },
    resolveUrl (url) {
      let newUrl = window.decodeURIComponent(url)

      newUrl = newUrl.trim().replace(/\s/g, '')

      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`
      }

      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`
      }

      return newUrl
    },
    openUpdateSecretDialog () {
      this.$emit('openUpdateSecretDialog', this.secret)
    },
    openDeleteSecretDialog () {
      this.$emit('openDeleteSecretDialog', this.secret)
    },
    closeViewSecretDialog () {
      this.$emit('closeViewSecretDialog', false)
    }
  }
}
</script>
